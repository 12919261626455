import React from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import colombianFlag from "../../assets/icons/Colombia.svg";
import usa from "../../assets/icons/usa.svg";

function LanguageSelector() {
  const [t, i18n] = useTranslation("common");
  const [language, setLanguage] = React.useState("es");
  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
    setLanguage(event.target.value);
  };
  return (
    <div className="w-[10rem]">
      <FormControl fullWidth>
        <Select
          type="None"
          id="demo-simple-select"
          value={language}
          onChange={handleChange}
          size="small"
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
          }}
        >
          <MenuItem value={"es"}>
            <div className="flex">
              <img className="px-2" src={colombianFlag} alt="colombianFlag" />
              {t("language.es")}
            </div>
          </MenuItem>
          <MenuItem value={"en"}>
            <div className="flex">
              <img className="px-2" src={usa} alt="usa" />
              {t("language.en")}
            </div>
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default LanguageSelector;
