import React from "react";
import alliesImage from "../../assets/images/alliesImage.svg";
import vectorBg from "../../assets/images/VectorAlliesBg.svg";
import hospital from "../../assets/icons/hospital.svg";
import healtcare from "../../assets/icons/healthcare.svg";
import heart from "../../assets/icons/AliadosHeart.svg";
import leader from "../../assets/icons/leaderboard-star.svg";
import imgblue from "../../assets/blueAllies.svg";
import "../../styles/index.css";
import { useTranslation } from "react-i18next";

function Allies() {
  const { t } = useTranslation("common");
  return (
    <div
      id="allies"
      className="flex flex-row w-full justify-center bg-[#323A73]"
    >
      <div className="hidden lg:flex ">
        <div className="grid grid-cols-3 z-10">
          <div className="relative">
            <img
              src={alliesImage}
              alt="alliesImage"
              className="relative  z-10 min-h-[45.4rem]"
              style={{ position: "relative" }}
            />
            <img
              src={vectorBg}
              alt="vectorBg"
              className="absolute bottom-0 z-0 min-h-[32rem] min-w-[51rem]"
              style={{ position: "absolute" }}
            />
          </div>
          <div className="flex flex-row gap-8 h-full relative right-56">
            <div className="flex flex-col relative min-w-[32rem] ">
              <div className="flex flex-col self-start">
                <p className="flex px-3  font-poppins text-3xl font-bold text-justify tracking-[-0.97px] leading-normal text-[#ffffff]">
                  {t("allies.title_1")}
                </p>
                <p className="flex text-end justify-end font-poppins text-[3.5rem] font-bold  tracking-[0.03px] leading-normal text-[#B3DA8B]">
                  {t("allies.title_2")}
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-11 items-center justify-center">
              <div className="flex flex-col items-center justify-center  gap-9 ">
                <div className="flex flex-row group group-hover:block">
                  <div className="flex flex-col alliesButtonslayout alliesButtons">
                    <img src={hospital} alt="hospital" />
                    <div className="font-poppins text-2xl font-bold text-justify tracking-[0.003px] leading-normal text-[#ffffff] ">
                      <p className="group-hover:hidden ">
                        {" "}
                        {t("allies.title_card_1")}
                      </p>
                      <p className="hidden group-hover:block cursor-pointer">
                        {t("allies.body_card_1")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col alliesButtonslayout alliesButtonsWellness group">
                  <img src={leader} alt="hospital" />
                  <div className="font-poppins text-2xl font-bold text-justify tracking-[0.003px] leading-normal text-[#ffffff]">
                    <p className="group-hover:hidden ">
                      {" "}
                      {t("allies.title_card_2")}
                    </p>
                    <p className="hidden group-hover:block cursor-pointer">
                      {t("allies.body_card_2")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-9 ">
                <div className="flex flex-row group">
                  <div className="flex flex-col alliesButtonslayout alliesButtonsLeaders">
                    <img src={healtcare} alt="hospital" />
                    <div className="font-poppins text-2xl font-bold text-justify tracking-[0.003px] leading-normal text-[#ffffff]">
                      <p className="group-hover:hidden ">
                        {" "}
                        {t("allies.title_card_3")}
                      </p>
                      <p className="hidden group-hover:block cursor-pointer">
                        {t("allies.body_card_3")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col alliesButtonslayout alliesButtonsHeart group">
                  <img src={heart} alt="hospital" />
                  <div className="font-poppins text-2xl font-bold text-justify tracking-[0.003px] leading-normal text-[#ffffff]">
                    <p className="group-hover:hidden ">
                      {" "}
                      {t("allies.title_card_4")}
                    </p>
                    <p className="hidden group-hover:block cursor-pointer">
                      {t("allies.body_card_4")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute z-0 right-0">
          <img src={imgblue} alt="imageblue" className="max-h-[45.4rem]" />
        </div>
      </div>
      <div className="flex lg:hidden">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <div className="flex flex-col ">
              <p className="flex  font-poppins text-2xl font-bold text-center text-[#ffffff]">
               {t("allies.title_1")}
              </p>
              <p className="flex self-center text-center font-poppins text-2xl font-bold text-[#B3DA8B]">
                {t("allies.title_2")}
              </p>
            </div>
          </div>

          <div className="flex flex-row gap-3 py-10 items-center justify-center">
            <div className="flex flex-col items-center justify-center  gap-9 ">
              <div className="flex flex-row group group-hover:block">
                <div className="flex flex-col alliesButtonslayoutResponsive alliesButtons">
                  <img className="w-[3.3rem]" src={hospital} alt="hospital" />
                  <div className="font-poppins text-base font-bold text-justify text-[#ffffff] ">
                    <p className="group-hover:hidden ">{t("allies.title_card_1")}</p>
                    <p className="hidden group-hover:block cursor-pointer">
                      {t("allies.body_card_1")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col alliesButtonslayoutResponsive alliesButtonsWellness group">
                <img className="w-[3.3rem]" src={leader} alt="hospital" />
                <div className="font-poppins text-base font-bold text-justify text-[#ffffff]">
                  <p className="group-hover:hidden ">{t("allies.title_card_2")}</p>
                  <p className="hidden group-hover:block cursor-pointer">
                    {t("allies.body_card_2")}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-9 ">
              <div className="flex flex-row group">
                <div className="flex flex-col alliesButtonslayoutResponsive alliesButtonsLeaders">
                  <img className="w-[3.3rem]" src={healtcare} alt="hospital" />
                  <div className="font-poppins text-base font-bold text-justify text-[#ffffff]">
                    <p className="group-hover:hidden ">{t("allies.title_card_3")}</p>
                    <p className="hidden group-hover:block cursor-pointer">
                      {t("allies.body_card_3")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col alliesButtonslayoutResponsive alliesButtonsHeart group">
                <img className="w-[3.3rem]" src={heart} alt="hospital" />
                <div className="font-poppins text-base font-bold text-justify text-[#ffffff]">
                  <p className="group-hover:hidden ">{t("allies.title_card_4")}</p>
                  <p className="hidden group-hover:block cursor-pointer">
                    {t("allies.body_card_4")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Allies;
