import React from "react";
import bigHeart from "../../assets/images/bigHeart.svg";
import "../../styles/index.css";
import SliderTopDivider from "../../components/sliderDivider/sliderDivider";
import { useTranslation } from "react-i18next";

function AboutUs() {
  const { t } = useTranslation("common");
  return (
    <div
      id="aboutUs"
      className=" flex flex-col w-full mt-[3.5rem] h-full lg:min-h-[42rem] bg-aboutUs"
    >
      <div className="hidden lg:block flex-col w-100 max-w-[1024px] m-auto gap-2  ">
        <div className="flex flex-row justify-center  gap-[0.9rem] mb-8">
          <p className="flex font-poppins text-5xl font-bold text-[#323A73]">
            {t("about_us.titulo_1")}
          </p>
          <p className="flex font-poppins text-5xl font-bold text-[#81C23D]">
            {t("about_us.titulo_2")}
          </p>
        </div>
        <div className="grid grid-cols-3 h-full justify-center items-center">
          <div className=" flex flex-col   ">
            <p className="font-poppins text-justify max-w-[23rem] text-[#8489AB] text-[1rem]">
              {t("about_us.resumen_1")}
              <span className="text-[#81C23D]">
                {" "}
                {t("about_us.resumen_2")}{" "}
              </span>
              {t("about_us.resumen_3")}
            </p>
          </div>
          <div className="flex justify-center items-center">
            <img
              src={bigHeart}
              alt="big heart"
              className="h-[16.8rem] w-[18.8rem] heartbeat"
            />
          </div>
          <div className="flex flex-col gap-6 w-full">
            <div className="flex py-5 px-6 flex-col  borderColor ">
              <div className="flex justify-start ">
                <p className="font-poppins font-semibold pb-2  text-[1.3rem] text-[#81C23D]">
                  {t("about_us.mision")}
                </p>
              </div>
              <div>
                <p className="font-poppins text-justify text-[#8489AB] text-[0.8rem]">
                  {t("about_us.mision_body")}
                </p>
              </div>
            </div>
            <div className="flex py-5 px-6 flex-col  borderColor">
              <div className="flex  justify-start">
                <p className="font-poppins font-semibold pb-2 text-[1.3rem] text-[#81C23D]">
                  {t("about_us.vision")}
                </p>
              </div>
              <div>
                <p className="font-poppins text-justify text-[#8489AB] text-[0.8rem]">
                  {t("about_us.vision_body")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* reponsive aboutUs */}
      <div className="lg:hidden flex-auto w-[100%] max-w-[32rem] m-auto gap-2  ">
        <div className="flex items-center flex-col justify-center gap-[0.2rem] mb-8">
          <p className="flex font-poppins text-3xl font-bold text-[#323A73]">
            {t("about_us.titulo_1")}
          </p>
          <p className="flex font-poppins text-3xl text-[#81C23D]">
            {t("about_us.titulo_2")}
          </p>
        </div>
        {/* a lot of text of cluster CHG */}
        <div className="">
          <div className="flex flex-row">
            <p className="font-poppins text-justify text-[#8489AB] text-[0.8rem] px-5">
              {t("about_us.resumen_1")}
              <span className="text-[#81C23D]">
                {" "}
                {t("about_us.resumen_2")}{" "}
              </span>
              {t("about_us.resumen_3")}
            </p>
          </div>
          {/* Misión & Visión */}
          <div className="flex flex-row ">
            <div className="flex flex-col gap-6 w-full">
              <div className="flex py-3 px-3 mx-8 mt-4 flex-col  borderColor ">
                <div className="flex justify-center ">
                  <p className="font-poppins font-semibold pb-2  text-[1.3rem] text-[#81C23D]">
                    {t("about_us.mision")}
                  </p>
                </div>
                <div className="flex flex-row px-2">
                  <p className="font-poppins text-justify text-[#8489AB] text-[0.72rem]">
                    {t("about_us.mision_body")}
                  </p>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <img
                  src={bigHeart}
                  alt="big heart"
                  className="w-[36%] heartbeat"
                />
              </div>
              <div
                className="flex pt-1 pb-3 px-3 mx-8 mb-4 flex-col relative bottom-4  borderColor "
              >
                <div className="flex  justify-center">
                  <p className="font-poppins font-semibold pb-2 text-[1.3rem] text-[#81C23D]">
                    {t("about_us.vision")}
                  </p>
                </div>
                <div className="flex flex-row">
                  <p className="font-poppins text-[#8489AB] text-[0.72rem] text-justify">
                    {t("about_us.vision_body")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block">
        <SliderTopDivider />
      </div>
    </div>
  );
}

export default AboutUs;
