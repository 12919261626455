import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
function CookiesConsent() {
  const { t } = useTranslation("common");
  const [cookie, setCookie] = useState({ "show": true });
  function handleCookie() {
    setCookie({show:false})
  }
  return (
    <>
      {cookie.show ? (
        <CookieConsent
          location="bottom"
          buttonText="Permitir todas"
          cookieName="CookieConsent"
          style={{ background: "#FFFFFF", color: "#000000" }}
          buttonStyle={{
            color: "#FFFFFF",
            background: "#323A73",
            fontSize: "13px",
          }}
          expires={259}
          visible
          enableDeclineButton={true}
          declineButtonText="Rechazar"
          acceptOnScroll={true}
          acceptOnScrollPercentage={50}
          onAccept={handleCookie}
          onDecline={handleCookie}
        >
          {t("cookies.title")}
          <br />
          <span style={{ fontSize: "10px" }}>{t("cookies.text")}</span>
        </CookieConsent>
      ):
      <></>
    }
    </>
  );
}
export default CookiesConsent;
