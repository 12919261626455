import React, { useState } from "react";
import bannerImage from "../../assets/images/pngwing 2.svg";
import iconButton from "../../assets/icons/button.svg";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation("common");
  const [emailTo, setEmailTo] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_18djsgf",
        "template_lhn3uqv",
        document.querySelector("#formData"),
        "bCYLYypHI5fGgcDzL"
      )
      .then(
        (result) => {
          Swal.fire({
            title: "Tu correo ha sido enviado exitosamente",
            text: "Gracias por contactarte con Caribbean Health Group. Próximamente nos estaremos comunicando.",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
          setEmailTo("");
        },
        (error) => {
          Swal.fire({
            title: "Error al enviar el correo",
            text: "Ocurrió un error al tratar de enviar el correo, por favor intente nuevamente.",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      );
  };
 
 
 
  return (
    <div id="contact" className="flex flex-col w-full  bg-contactBg">
      <div className="flex flex-col w-100 max-w-[1024px] m-auto ">
        <div className="flex flex-col justify-center items-center lg:relative lg:top-6 lg:right-28">
          <p className="flex font-poppins text-[2rem] lg:text-[4rem] font-extrabold text-[#323A73]">
            {t("contact.title_1")}
          </p>
          <p className="flex font-poppins text-[2rem] lg:text-[4rem] font-extrabold lg:text-[#fff] text-[#B3DA8B]">
            {t("contact.title_2")}
          </p>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 py-5 items-center justify-items-center gap-5">
          <div
            style={{ width: "100%" }}
            className="flex flex-col sm:w-[70%] self-center lg:justify-center items-center "
          >
            <div className="flex flex-col ">
              <img className="" src={bannerImage} alt="BannerImage" />
            </div>
          </div>

          {/* quieres ser miembro aliado  */}
          <div className="lg:flex hidden z-10 py-4 px-6 h-30 flex-col items-start rounded-[1.5rem] border-[2px] border-solid border-[#fff] textBoxPrincipalBanner ">
            <div className="flex flex-col justify-center items-center gap-3">
              <div className="flex self-start ">
                <p className="font-poppins text-[1rem] tracking-wider text-white font-bold">
                  {t("principal_banner.miembro")}
                </p>
              </div>
              <form
                onSubmit={sendEmail}
                id="formData"
                className="flex flex-row gap-3"
              >
                <input
                  onChange={(e) => {
                    setEmailTo(e.target.value);
                  }}
                  value={emailTo}
                  name="email_allies"
                  type="email"
                  placeholder={t("principal_banner.correo")}
                  className="bg-transparent w-[18rem] text-white font-poppins text-[1rem] font-bold focus:border-[#fff] rounded-[1.5rem] border-[2px] border-solid border-[#fff]"
                ></input>
                <img
                  onClick={sendEmail}
                  src={iconButton}
                  alt="iconButton"
                  className="h-12 w-12 hover:cursor-pointer"
                />
              </form>
            </div>
          </div>
          {/* medium screen principal banner */}
          <div className="flex lg:hidden w-[90%] center z-10 py-4 px-6 flex-col justify-start items-start gap-4 rounded-[1.5rem] border-[2px] border-solid border-[#fff] textBoxPrincipalBanner ">
            <div className="flex flex-col justify-center items-center gap-3">
              <div className="flex self-start">
                <p className="font-poppins text-[1rem] tracking-wider text-white font-bold">
                  {t("principal_banner.miembro")}
                </p>
              </div>
              <form
                onSubmit={sendEmail}
                id="formData"
                className="flex flex-row gap-3"
              >
                <input
                  onChange={(e) => {
                    setEmailTo(e.target.value);
                  }}
                  value={emailTo}
                  name="email_allies"
                  type="email"
                  placeholder={t("principal_banner.correo")}
                  className="bg-transparent w-[18rem] text-white font-poppins text-[1rem] font-bold focus:border-[#fff] rounded-[1.5rem] border-[2px] border-solid border-[#fff]"
                ></input>
                <img
                  onClick={sendEmail}
                  src={iconButton}
                  alt="iconButton"
                  className="h-12 w-12 hover:cursor-pointer"
                />
              </form>
            </div>
          </div>
        

          
        </div>
      </div>
      <div className="flex relative">
        <div className="custom-shape-divider-bottomContact-1695183898 ">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default Contact;
