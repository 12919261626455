import "../styles/app.css"
import "../styles/index.css";
import NavMenu from "../components/header/navMenu";
import Footer from "../components/footer/footer";
import PrincipalBanner from "../components/principalBanner/principalBanner";
import AllyMembers from "../components/allyMembers/allyMembers";
import AboutUs from '../components/aboutUs/aboutUs';
import MedicalServices from '../components/medicalServices/medicalServices';
import Allies from '../components/allies/allies';
// import UserExperience from '../components/userExperience/userExperience';
import Slider from '../components/slider/slider';
import Divider from "../components/divider";
import SocialMedia from '../components/socialMedia/socialMedia'
import Contact from '../components/contact/contact' 
import { FaArrowUp } from "react-icons/fa";
import CookiesConsent from "../components/cookies/cookies";

function App() {

  return (
    <div className="flex flex-col max-w-[1920px] justify-between h-[100vh] ">
      <CookiesConsent/>
      <NavMenu />
      <PrincipalBanner /> 
      <AllyMembers/>
      <AboutUs/>
      <Slider/>
      <MedicalServices/>
      <Divider/>
      <Allies/>
      {/* <UserExperience/> */}
      <Contact/>
      <SocialMedia/>
      <Footer /> 
      <a  className="fixed-btn rounded-full w-8 h-8 border-4 border-indigo-500/50  bg-[#4369a5] items-center" href="#home">
        <div className="relative left-1 top-1">
        <FaArrowUp className="stroke-white"/>
        </div>
       
        </a> 
    </div>
  );
}

export default App;
