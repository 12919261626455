import React from "react";
import cocaf from "../../assets/images/Frame (2).svg";
import portoAzul from "../../assets/images/PortoAzul.png";
import otorhinoCenter from "../../assets/images/image 5.svg";
import generalDelNorte from "../../assets/images/image 7.svg";
import laMisericordia from "../../assets/images/image 6.svg";
import reencontrarse from "../../assets/images/image 16.svg";
import carlosAcevedo from "../../assets/images/carlos_acevedo_logo.png";
import logoFusa from "../../assets/images/logo_fusa.png";
import itc from "../../assets/images/image 19.svg";
import previsalud from "../../assets/images/LOGO-01.png";
import iberoAmerica from "../../assets/images/LOGO CLINICA IBEROAMERICA-01.png";
import "../../styles/index.css";
import { useTranslation } from "react-i18next";

function AllyMembers() {
  const {t} = useTranslation("common");
  return (
    <div
      id="alliesMembers"
      className="flex w-100 max-w-[1024px] m-auto flex-row w-full"
    >
      <div className="lg:flex hidden flex-col w-full ">
        <div className="flex flex-row gap-3 h-12 py-20">
          <p className="flex font-poppins text-5xl font-bold text-[#323A73]">
            {t("ally_member.miembros")}
          </p>
          <p className="flex font-poppins font-bold text-5xl text-[#81C23D]">
          {t("ally_member.aliados")}
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col">
            <div className="flex justify-evenly">
              <div>
                <img src={cocaf} alt="clinica oftalmologica del caribe" />
              </div>
              <div>
                <img
                  src={portoAzul}
                  alt="clinica porto azul"
                  style={{ width: "80%" }}
                />
              </div>
              <div>
                <img src={otorhinoCenter} alt="Otorhino Center" />
              </div>
              <div>
                <img src={generalDelNorte} alt="clinica general del norte" />
              </div>
              <div>
                <img
                  src={laMisericordia}
                  alt="la misericordia clinica internacional"
                />
              </div>
            </div>
          </div>
          <div className="flex ">
            <div className="flex flex-row w-full justify-between items-center">
              <div>
                <img
                  src={reencontrarse}
                  alt="reencontrarse centro terapeutico"
                  style={{ width: "293px", height: "72px" }}
                />
              </div>
              <div>
                <img src={carlosAcevedo} alt="odontología" />
              </div>
              <div>
                <img
                  src={itc}
                  alt="transplante de medula osea"
                  style={{ width: "293px", height: "72px" }}
                />
              </div>
              <div>
                <img
                  src={previsalud}
                  alt="previsalud"
                  style={{ width: "293px" }}
                />
              </div>
              <div className="w-[15%]">
                <img src={iberoAmerica} alt="ibero america" />
              </div>
              <div  className="w-[25%]">
                <img
                  src={logoFusa}
                  alt="fusa"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* responsive ally Members */}
      <div className="flex lg:hidden flex-col w-full ">
        <div className="flex flex-row gap-3 h-12 py-16 justify-center">
          <p className="flex font-poppins text-3xl font-bold text-[#323A73]">
            {t("ally_member.miembros")}
          </p>
          <p className="flex font-poppins font-bold text-3xl text-[#81C23D]">
            {t("ally_member.aliados")}
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <div className="">
            <div className="flex justify-evenly max-w-[80% flex-row justify-center] px-2 ">
              <div>
                <img
                  src={cocaf}
                  alt="clinica oftalmologica del caribe"
                  style={{ width: "90%" }}
                />
              </div>
              <div>
                <img
                  src={portoAzul}
                  alt="clinica porto azul"
                  style={{ width: "90%" }}
                />
              </div>
              <div>
                <img
                  src={otorhinoCenter}
                  alt="Otorhino Center"
                  style={{ width: "90%" }}
                />
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex justify-evenly max-w-[80% flex-row justify-center] px-2 ">
              <div>
                <img
                  src={generalDelNorte}
                  alt="clinica general del norte"
                  style={{ width: "90%" }}
                />
              </div>
              <div>
                <img
                  src={laMisericordia}
                  alt="la misericordia clinica internacional"
                  style={{ width: "90%" }}
                />
              </div>
              <div className="">
                <img
                  src={reencontrarse}
                  alt="reencontrarse centro terapeutico"
                  style={{ width: "90%" }}
                />
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex gap-2 justify-evenly px-2 max-w-[80% flex-row justify-center] items-center ">
              <div className="w-[40%]">
                <img
                  src={carlosAcevedo}
                  alt="odontología" 
                />
              </div>
              <div className="w-[45%]">
                <img
                  src={itc}
                  alt="transplante de medula osea" 
                />
              </div>
              <div className="w-[45%]">
                <img
                  src={previsalud}
                  alt="previsalud"
                  style={{ width: "293px" }}
                />
              </div>              
              <div className="w-[25%]">
                <img
                  src={iberoAmerica}
                  alt="ibero america"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex gap-2 justify-center px-2">
            <div className="w-[30%]">
                <img
                  src={logoFusa}
                  alt="fusa" 
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllyMembers;
