/* import { Carousel } from "flowbite-react"; */
import cofcaWoman from "../assets/images/cofca_1.png";
import cofcaLogo from "../assets/sliderframe1/Logo cofca.svg";
import imagePortoAzul from "../assets/images/PORTO-AZUL-2.png";
import logoPortoAzul from "../assets/images/PORTO AZUL 2.png";
import otorhinoCenter from "../assets/images/othorino1.webp";
import otorhinoCenterLogo from "../assets/sliderframe3/otorhino-center-logo 1.svg";
import nortImage from "../assets/images/GENERAL-DEL-NORTE.png";
import nortLogo from "../assets/sliderframe4/nortLogo.svg";
import mercyImage from "../assets/sliderframe5/mercyImage.png";
import mercyLogo from "../assets/sliderframe5/mercyLogo.svg";
import rediscoverImage from "../assets/sliderframe6/rediscoverImage.svg";
import rediscoverLogo from "../assets/sliderframe6/rediscoverLogo.svg";
import CAImage from "../assets/images/Carlos-Acevedo.webp";
import CALogo from "../assets/images/carlos_acevedo_logo.png";
import ITCImage from "../assets/sliderframe8/ITCImage.png";
import ITCLogo from "../assets/sliderframe8/ITCLogo.svg";
import iberoamericaImage from "../assets/images/IBERO.png";
import iberoamericaLogo from "../assets/images/LOGO CLINICA IBEROAMERICA-02.png";
import fusa from "../assets/images/logo_fusa.png";
import medFusa from "../assets/images/MEDICO-1.png";
import Circulos from "../assets/images/Circulos.svg";
import previsalud from "../assets/images/LOGO-03.png";
import previH from "../assets/images/previsalud.png";
import line from "../assets/images/Rectangle 533.svg";
import "../styles/divider.css";
import Button1 from "./button";
import phoneIcon from "../assets/icons/Phone.svg";
import email2 from "../assets/icons/email2.svg";
import ubication from "../assets/icons/Point2.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

export default function DefaultCarousel() {
  const { t } = useTranslation("common");
  const slides = [
    <>
      <div className="flex flex-row  mt-10 ">
        <div className="flex flex-col flex-1 justify-center items-center gap-5 lg:mb-48">
          <div className="flex items-center flex-col gap-3 lg:gap-10">
            <img className="w-[75%]" src={cofcaLogo} alt="Logo Banner" />
            <img src={line} alt="line" style={{ width: "551px" }} />
          </div>
          <div className="flex flex-col lg:w-[60%] w-[80%] text-center ">
            <p className="font-poppins text-2xl text-white">
              {t("slider.slider_1_text")}
            </p>
          </div>
          <img src={line} alt="line" style={{ width: "551px" }} />
          <div className="flex lg:w-[36.5rem] py-4 px-6 flex-col gap-4 ">
            <div className="flex flex-col  gap-3">
              <div className="grid grid-cols-2">
                <div className="flex flex-col gap-6">
                  <div className="flex gap-2">
                    <img src={phoneIcon} alt="phoneIcon" />
                    <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                      +57 318 347 0539
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img src={email2} alt="email2" />
                    <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                      info@cofca.com
                    </p>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <img src={ubication} alt="ubication" />
                  <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                    Cra 51B N 87 – 50 piso 3 C.C Viva Barranquilla, Atlántico
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center ">
              <Button1 url={"https://cofca.com/"} />
            </div>
          </div>
        </div>
        <div className="hidden lg:flex justify-center items-center relative ">
          <img
            src={Circulos}
            alt="Cirulos"
            className=" w-[60rem] relative h-[52rem]  "
          />
          { }
          <img
            className="absolute h-[100%] bottom-[0rem] "
            src={cofcaWoman}
            alt="BannerImage"
          />
        </div>
      </div>

      <div className="hidden lg:flex relative ">
        <div className="custom-shape-divider-bottomSliderWave-1695183898 ">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </>,
    <>
      <div className="flex flex-row  mt-10 ">
        <div className="flex flex-col flex-1 justify-center items-center gap-5 lg:mb-48">
          <div className="flex flex-col gap-3 items-center">
            <img
              src={logoPortoAzul}
              alt="Logo Banner"
              style={{ width: "36%" }}
            />
            <img src={line} alt="line" style={{ width: "551px" }} />
          </div>
          <div className="flex flex-col lg:w-[60%] w-[80%] ">
            <p className="font-poppins text-2xl text-center text-white">
              {t("slider.slider_2_text")}
            </p>
          </div>
          <img src={line} alt="line" style={{ width: "551px" }} />
          <div className="flex lg:w-[36.5rem] py-4 px-6 flex-col gap-4 ">
            <div className="flex flex-col  gap-3">
              <div className="grid grid-cols-2">
                <div className="flex flex-col gap-6">
                  <div className="flex gap-2">
                    <img src={phoneIcon} alt="phoneIcon" />
                    <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                      +57 321 859 2178
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img src={email2} alt="email2" />
                    <a
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "80%",
                      }}
                      href="mailto:karen.garcia@clinicaportoazul.com"
                      className="font-poppins text-white tracking-[0.5px] leading-snug"
                    >
                      karen.garcia@clinicaportoazul.com
                    </a>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <img src={ubication} alt="ubication" />
                  <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                    KM2 Vía Puerto Colombia, Puerto Colombia, Atlántico
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center ">
              <Button1 url={"https://www.clinicaportoazul.com/"} />
            </div>
          </div>
        </div>
        <div className="hidden lg:flex justify-center items-center relative ">
          <img
            src={Circulos}
            alt="Cirulos"
            className=" w-[60rem] relative h-[52rem]  "
          />
          { }
          <img
            className="absolute w-[100%] top-[20%] "
            src={imagePortoAzul}
            alt="BannerImage"
          />
        </div>
      </div>

      <div className="hidden lg:flex relative ">
        <div className="custom-shape-divider-bottomSliderWave-1695183898 ">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </>,
    <>
      <div className="flex flex-row  mt-10 ">
        <div className="flex flex-col flex-1 justify-center items-center gap-5 lg:mb-48">
          <div className="flex flex-col lg:gap-10">
            <img
              src={otorhinoCenterLogo}
              alt="Logo Banner"
              style={{ width: "551px", height: "127px" }}
            />
            <img src={line} alt="line" style={{ width: "551px" }} />
          </div>
          <div className="flex flex-col lg:w-[60%] w-[80%] ">
            <p className="font-poppins text-2xl text-center text-white">
              {t("slider.slider_3_text")}
            </p>
          </div>
          <img src={line} alt="line" style={{ width: "551px" }} />
          <div className="flex lg:w-[36.5rem] py-4 px-6 flex-col gap-4 ">
            <div className="flex flex-col  gap-3">
              <div className="grid grid-cols-2">
                <div className="flex flex-col gap-6">
                  <div className="flex gap-2">
                    <img src={phoneIcon} alt="phoneIcon" />
                    <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                      +57 313 586 0691
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img src={email2} alt="email2" />
                    <a
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "80%",
                      }}
                      href="mailto:servicioalcliente@otorhinocenter.com"
                      className="font-poppins text-white tracking-[0.5px] leading-snug"
                    >
                      servicioalcliente@otorhinocenter.com
                    </a>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <img src={ubication} alt="ubication" />
                  <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                    Cra. 50 #82-185 Barranquilla, Atlántico
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center ">
              <Button1 url={"https://otorhinocenter.com/"} />
            </div>
          </div>
        </div>
        <div className="hidden lg:flex justify-center items-center relative ">
          <img
            src={Circulos}
            alt="Cirulos"
            className=" w-[60rem] relative h-[52rem]  "
          />
          { }
          <img
            className="absolute w-[100%] h-[94%]  bottom-[0rem] "
            src={otorhinoCenter}
            alt="BannerImage"
          />
        </div>
      </div>

      <div className="hidden lg:flex relative ">
        <div className="custom-shape-divider-bottomSliderWave-1695183898 ">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </>,
    <>
      <div className="flex flex-row  mt-10 ">
        <div className="flex flex-col flex-1 justify-center items-center gap-5 lg:mb-48">
          <div className="flex flex-col lg:gap-10">
            <img
              src={nortLogo}
              alt="Logo Banner"
              style={{ width: "551px", height: "127px" }}
            />
            <img src={line} alt="line" style={{ width: "551px" }} />
          </div>
          <div className="flex flex-col lg:w-[60%] w-[90%] ">
            <p className="font-poppins text-2xl text-center text-white">
              {t("slider.slider_4_text")}
            </p>
          </div>
          <img src={line} alt="line" style={{ width: "551px" }} />
          <div className="flex lg:w-[36.5rem] py-4 px-6 flex-col gap-4 ">
            <div className="flex flex-col  gap-3">
              <div className="grid grid-cols-2">
                <div className="flex flex-col gap-6">
                  <div className="flex gap-2">
                    <img src={phoneIcon} alt="phoneIcon" />
                    <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                      +57 318 347 0539
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img src={email2} alt="email2" />
                    <a
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "80%",
                      }}
                      href="mailto:magisteriosiaur6@clinicageneraldelnorte.com"
                      className="font-poppins text-white tracking-[0.5px] leading-snug"
                    >
                      magisteriosiaur6@clinicageneraldelnorte.com
                    </a>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <img src={ubication} alt="ubication" />
                  <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                    Calle 70 # 48-35 Barranquilla, Atlántico
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center ">
              <Button1 url={"https://www.clinicageneraldelnorte.com/"} />
            </div>
          </div>
        </div>
        <div className="hidden lg:flex justify-center items-center relative ">
          <img
            src={Circulos}
            alt="Cirulos"
            className=" w-[60rem] relative h-[52rem]  "
          />
          { }
          <img
            className="absolute w-[82%] top-0 "
            src={nortImage}
            alt="BannerImage"
          />
        </div>
      </div>

      <div className="hidden lg:flex relative ">
        <div className="custom-shape-divider-bottomSliderWave-1695183898 ">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </>,
    <>
      <div className="flex flex-row  mt-10 ">
        <div className="flex flex-col flex-1 justify-center items-center gap-5 lg:mb-48">
          <div className="flex flex-col lg:gap-10">
            <img
              src={mercyLogo}
              alt="Logo Banner"
              style={{ width: "551px", height: "127px" }}
            />
            <img src={line} alt="line" style={{ width: "551px" }} />
          </div>
          <div className="flex flex-col lg:w-[60%] w-[90%] ">
            <p className="font-poppins text-2xl text-center text-white">
              {t("slider.slider_5_text")}
            </p>
          </div>
          <img src={line} alt="line" style={{ width: "551px" }} />
          <div className="flex lg:w-[36.5rem] py-4 px-6 flex-col gap-4 ">
            <div className="flex flex-col  gap-3">
              <div className="grid grid-cols-2">
                <div className="flex flex-col gap-6">
                  <div className="flex gap-2">
                    <img src={phoneIcon} alt="phoneIcon" />
                    <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                      +57 320 2653398
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img src={email2} alt="email2" />
                    <a
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "80%",
                      }}
                      href="mailto:info@lmci.com.co"
                      className="font-poppins text-white tracking-[0.5px] leading-snug"
                    >
                      info@lmci.com.co
                    </a>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <img src={ubication} alt="ubication" />
                  <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                    Cra.74 #76-91 Barranquilla, Atlántico
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center ">
              <Button1 url={"https://lmci.com.co/"} />
            </div>
          </div>
        </div>
        <div className="hidden lg:flex justify-center items-center relative ">
          <img
            src={Circulos}
            alt="Cirulos"
            className=" w-[60rem] relative h-[52rem]  "
          />
          { }
          <img
            className="absolute h-[100%] bottom-[0rem] "
            src={mercyImage}
            alt="BannerImage"
          />
        </div>
      </div>

      <div className="hidden lg:flex relative ">
        <div className="custom-shape-divider-bottomSliderWave-1695183898 ">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </>,
    <>
      <div className="flex flex-row  mt-10 ">
        <div className="flex flex-col flex-1 justify-center items-center gap-5 lg:mb-48">
          <div className="flex flex-col lg:gap-10">
            <img
              src={rediscoverLogo}
              alt="Logo Banner"
              style={{ width: "551px", height: "127px" }}
            />
            <img src={line} alt="line" style={{ width: "551px" }} />
          </div>
          <div className="flex flex-col w-[90%] lg:w-[60%] ">
            <p className="font-poppins text-2xl text-center text-white">
              {t("slider.slider_6_text")}
            </p>
          </div>
          <img src={line} alt="line" style={{ width: "551px" }} />
          <div className="flex lg:w-[36.5rem] py-4 px-6 flex-col gap-4 ">
            <div className="flex flex-col  gap-3">
              <div className="grid grid-cols-2">
                <div className="flex flex-col gap-6">
                  <div className="flex gap-2">
                    <img src={phoneIcon} alt="phoneIcon" />
                    <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                      (605) 3596770
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img src={email2} alt="email2" />
                    <a
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "80%",
                      }}
                      href="mailto:callcenter@ips-reencontrarse.com"
                      className="font-poppins text-white tracking-[0.5px] leading-snug"
                    >
                      callcenter@ips-reencontrarse.com
                    </a>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <img src={ubication} alt="ubication" />
                  <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                    Carrera 45 # 84 - 197 Barranquilla, Atlántico
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center ">
              <Button1 url={"https://reencontrarse.com/"} />
            </div>
          </div>
        </div>
        <div className="hidden lg:flex justify-center items-center relative ">
          <img
            src={Circulos}
            alt="Cirulos"
            className=" w-[60rem] relative h-[52rem]  "
          />
          { }
          <img
            className="absolute h-[100%] bottom-[0rem] "
            src={rediscoverImage}
            alt="BannerImage"
          />
        </div>
      </div>

      <div className="hidden lg:flex relative ">
        <div className="custom-shape-divider-bottomSliderWave-1695183898 ">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </>,
    <>
      <div className="flex flex-row  mt-10 ">
        <div className="flex flex-col flex-1 justify-center items-center gap-5 lg:mb-48">
          <div className="flex flex-col lg:gap-10">
            <img src={CALogo} alt="Logo Banner" style={{ height: "70%" }} />
            <img src={line} alt="line" style={{ width: "551px" }} />
          </div>
          <div className="flex flex-col w-[90%] lg:w-[60%]">
            <p className="font-poppins text-2xl text-white text-center">
              {t("slider.slider_7_text")}
            </p>
          </div>
          <img src={line} alt="line" style={{ width: "551px" }} />
          <div className="flex lg:w-[36.5rem] py-4 px-6 flex-col gap-4 ">
            <div className="flex flex-col  gap-3">
              <div className="grid grid-cols-2">
                <div className="flex flex-col gap-6">
                  <div className="flex gap-2">
                    <img src={phoneIcon} alt="phoneIcon" />
                    <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                      +57 320 5491349
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img src={email2} alt="email2" />
                    <a
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "80%",
                      }}
                      href="mailto:info@carlosacevedo.co"
                      className="font-poppins text-white tracking-[0.5px] leading-snug"
                    >
                      info@carlosacevedo.co
                    </a>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <img src={ubication} alt="ubication" />
                  <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                    Calle 106 No. 50-67 Local 405
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center ">
              <Button1 url={"https://carlosacevedo.co/"} />
            </div>
          </div>
        </div>
        <div className="hidden lg:flex justify-center items-center relative ">
          <img
            src={Circulos}
            alt="Cirulos"
            className=" w-[60rem] relative h-[52rem]  "
          />
          { }
          <img
            className="absolute h-[100%] bottom-[0rem] "
            src={CAImage}
            alt="BannerImage"
          />
        </div>
      </div>

      <div className="hidden lg:flex relative ">
        <div className="custom-shape-divider-bottomSliderWave-1695183898 ">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </>,
    //antes de la ultima
    <>
      <div className="flex flex-row  mt-10 ">
        <div className="flex flex-col flex-1 justify-center items-center gap-5 lg:mb-48">
          <div className="flex flex-col lg:gap-10">
            <img
              src={ITCLogo}
              alt="Logo Banner"
              style={{ width: "551px", height: "127px" }}
            />
            <img src={line} alt="line" style={{ width: "551px" }} />
          </div>
          <div className="flex flex-col w-[90%] lg:w-[60%] ">
            <p className="font-poppins text-2xl text-white text-center">
              {t("slider.slider_8_text")}
            </p>
          </div>
          <img src={line} alt="line" style={{ width: "551px" }} />
          <div className="flex lg:w-[36.5rem] py-4 px-6 flex-col gap-4 ">
            <div className="flex flex-col  gap-3">
              <div className="grid grid-cols-2">
                <div className="flex flex-col gap-6">
                  <div className="flex gap-2">
                    <img src={phoneIcon} alt="phoneIcon" />
                    <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                      +57 310 471 7418
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img src={email2} alt="email2" />
                    {/* <a
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "80%",
                      }}
                      href="mailto:info@carlosacevedo.co"className="font-poppins text-white tracking-[0.5px] leading-snug">
                      info@cofca.com
                    </a> */}
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <img src={ubication} alt="ubication" />
                  <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                    Cl. 70b #38-37 Barranquilla, Atlántico
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center ">
              <Button1 url={"https://itcmedulaosea.com/"} />
            </div>
          </div>
        </div>
        <div className="hidden lg:flex justify-center items-center relative ">
          <img
            src={Circulos}
            alt="Cirulos"
            className=" w-[60rem] relative h-[52rem]  "
          />
          { }
          <img
            className="absolute h-[100%] bottom-[0rem] "
            src={ITCImage}
            alt="BannerImage"
          />
        </div>
      </div>

      <div className="hidden lg:flex relative ">
        <div className="custom-shape-divider-bottomSliderWave-1695183898 ">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </>,
    //last
    <>
      <div className="flex flex-row  mt-10 ">
        <div className="flex flex-col flex-1 justify-center items-center gap-5 lg:mb-48">
          <div className="flex flex-col gap-2 items-center ">
            <img
              src={iberoamericaLogo}
              alt="Logo Banner"
              style={{ width: "29%" }}
            />
            <img src={line} alt="line" style={{ width: "551px" }} />
          </div>
          <div className="flex flex-col w-[90%] lg:w-[60%] ">
            <p className="font-poppins text-2xl text-white text-center">
              {t("slider.slider_9_text")}
            </p>
          </div>
          <img src={line} alt="line" style={{ width: "551px" }} />
          <div className="flex lg:w-[36.5rem] py-4 px-6 flex-col gap-4 ">
            <div className="flex flex-col  gap-3">
              <div className="grid grid-cols-2">
                <div className="flex flex-col gap-6">
                  <div className="flex gap-2">
                    <img src={phoneIcon} alt="phoneIcon" />
                    <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                      605 3319424
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img src={email2} alt="email2" />
                    <a
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "80%",
                      }}
                      href="mailto:novedadteleconsulta@colsanitas.com"
                      className="font-poppins text-white tracking-[0.5px] leading-snug"
                    >
                      novedadteleconsulta@colsanitas.com
                    </a>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <img src={ubication} alt="ubication" />
                  <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                    Calle 86 # 50-26 Barranquilla, Atlántico
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center ">
              <Button1 url={"https://www.clinicaiberoamerica.com/"} />
            </div>
          </div>
        </div>
        <div className="hidden lg:flex justify-center items-center relative ">
          <img
            src={Circulos}
            alt="Cirulos"
            className=" w-[60rem] relative h-[52rem]  "
          />
          { }
          <img
            className="absolute w-[99%]  top-0 left-2 "
            src={iberoamericaImage}
            alt="BannerImage"
          />
        </div>
      </div>

      <div className="hidden lg:flex relative ">
        <div className="custom-shape-divider-bottomSliderWave-1695183898 ">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </>,
    // Previsalud
    <>
      <div className="flex flex-row  mt-10 ">
        <div className="flex flex-col flex-1 justify-center items-center gap-5 lg:mb-48">
          <div className="flex flex-col gap-2 items-center ">
            <img
              src={previsalud}
              alt="Logo Banner"
              style={{ width: "29%" }}
            />
            <img src={line} alt="line" style={{ width: "551px" }} />
          </div>
          <div className="flex flex-col w-[90%] lg:w-[60%] ">
            <p className="font-poppins text-2xl text-white text-center">
              {t("slider.slider_10_text")}
            </p>
          </div>
          <img src={line} alt="line" style={{ width: "551px" }} />
          <div className="flex lg:w-[36.5rem] py-4 px-6 flex-col gap-4 ">
            <div className="flex flex-col  gap-3">
              <div className="grid grid-cols-2">
                <div className="flex flex-col gap-6">
                  <div className="flex gap-2">
                    <img src={phoneIcon} alt="phoneIcon" />
                    <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                      605 3851778 Ext. 5801
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img src={email2} alt="email2" />
                    <a
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "80%",
                      }}
                      href="mailto:novedadteleconsulta@colsanitas.com"
                      className="font-poppins text-white tracking-[0.5px] leading-snug"
                    >
                      
                    </a>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <img src={ubication} alt="ubication" />
                  <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                  Calle 85 #50 – 159 of 1404 Barranquilla, Atlántico
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center ">
              <Button1 url={"https://previsalud.com.co//"} />
            </div>
          </div>
        </div>
        <div className="hidden lg:flex justify-center items-center relative ">
          <img
            src={Circulos}
            alt="Cirulos"
            className=" w-[60rem] relative h-[52rem]  "
          />
          { }
          <img
            className="absolute w-[55%]  bottom-[0rem] "
            src={previH}
            alt="BannerImage"
          />
        </div>
      </div>

      <div className="hidden lg:flex relative ">
        <div className="custom-shape-divider-bottomSliderWave-1695183898 ">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </>,


    // Fusa
    <>
      <div className="flex flex-row  mt-10 ">
        <div className="flex flex-col flex-1 justify-center items-center gap-5 lg:mb-48">
          <div className="flex flex-col gap-2 items-center ">
            <img
              src={fusa}
              alt="Logo Banner"
              style={{ width: "29%" }}
            />
            <img src={line} alt="line" style={{ width: "551px" }} />
          </div>
          <div className="flex flex-col w-[90%] lg:w-[60%] ">
            <p className="font-poppins text-2xl text-white text-center">
              {t("slider.slider_11_text")}
            </p>
          </div>
          <img src={line} alt="line" style={{ width: "551px" }} />
          <div className="flex lg:w-[36.5rem] py-4 px-6 flex-col gap-4 ">
            <div className="flex flex-col  gap-3">
              <div className="grid grid-cols-2">
                <div className="flex flex-col gap-6">
                  <div className="flex gap-2">
                    <img src={phoneIcon} alt="phoneIcon" />
                    <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                      605 3198946
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img src={email2} alt="email2" />
                    <a
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "80%",
                      }}
                      href="mailto:notificaciones@ipsfusa.com"
                      className="font-poppins text-white tracking-[0.5px] leading-snug"
                    >
                      notificaciones@ipsfusa.com                     
                    </a>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <img src={ubication} alt="ubication" />
                  <p className="font-poppins text-white tracking-[0.5px] leading-snug">
                  Carrera 56 #72 - 144 Barranquilla, Atlántico
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center ">
              <Button1 url={"https://ipsfusa.com/"} />
            </div>
          </div>
        </div>
        <div className="hidden lg:flex justify-center items-center relative ">
          <img
            src={Circulos}
            alt="Cirulos"
            className=" w-[60rem] relative h-[52rem]  "
          />
          { }
          <img
            className="absolute w-[90%] bottom-[0rem] "
            src={medFusa}
            alt="BannerImage"
          />
        </div>
      </div>

      <div className="hidden lg:flex relative ">
        <div className="custom-shape-divider-bottomSliderWave-1695183898 ">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </>
  ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
  };
  return (
    <div className="flex flex-col w-full h-full bg-bgSliderOne ">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index}>{slide}</div>
        ))}
      </Slider>
    </div>
  );
}
