import { React, useState } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/solid";
import logo from "../../assets/logo.svg";
import iconHome from "../../assets/icons/iconamoon_home-bold.svg";
import iconServices from "../../assets/icons/servicios.svg";
import iconAllies from "../../assets/icons/aliados.svg";
import iconContact from "../../assets/icons/email.svg";
import iconAboutUs from "../../assets/icons/account-circle-line.svg"; 
import iconHeart from "../../assets/icons/Heart.svg";
// import iconFind from "../../assets/icons/search-normal.svg"; 
import "../../styles/index.css";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../languageSelector/languageSelector"

function NavMenu() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation("common");

  return (
    <div className="z-10 flex flex-col md:flex-row py-3 px-6 justify-between md:content-center">
      <div className="flex flex-row items-center gap-3 self-stretch ">
        <img src={logo} alt="logo" className="relative left-24" />
      </div>
      <div className="hidden md:flex md:flex-row mt-2">
        <ul className="flex flex-row gap-8 text-[#323A73] font-poppins text-[1rem] ">
          <li className="flex gap-2 cursor-pointer">
            <a className="flex" href="#home">
              <img
                src={iconHome}
                alt="iconHome"
                style={{ width: "24px", height: "24px" }}
              />
              <p className="pl-2">{t("header.inicio")}</p>
            </a>
          </li>
          <li className="flex gap-2 cursor-pointer">
            <a className="flex" href="#aboutUs">
              <img
                src={iconAboutUs}
                alt="IconAboutUs"
                style={{ width: "24px", height: "24px" }}
              />
              <p className="pl-2">{t("header.nosotros")}</p>
            </a>
          </li>
          <li className="flex gap-2 cursor-pointer">
            <a className="flex" href="#services">
              <img
                src={iconServices}
                alt="iconServices"
                style={{ width: "24px", height: "24px" }}
              />
              <p className="pl-2">{t("header.servicios")}</p>
            </a>
          </li>
          <li className="flex gap-2 cursor-pointer">
            <a className="flex" href="#slider">
              <img
                src={iconAllies}
                alt="IconAllies"
                style={{ width: "24px", height: "24px" }}
              />
              <p className="pl-2">{t("header.aliados")}</p>
            </a>
          </li>
          <li className="flex gap-2 cursor-pointer">
            <a className="flex" href="#contact">
              <img
                src={iconContact}
                alt="iconContact"
                style={{ width: "24px", height: "24px" }}
              />
              <p className="pl-2">{t("header.contacto")}</p>
            </a>
          </li>
          <li className="flex gap-2 cursor-pointer">
            <a className="flex" href="#SocialMedia">
              <img
                src={iconHeart}
                alt="iconHeart"
                style={{ width: "24px", height: "24px" }}
              />
              <p
                onClick={() => scrollToSection("SocialMedia")}
                className="pl-2"
              >
                {t("header.social")}
              </p>
            </a>
          </li>
        </ul>
      </div>
      {/* <div className="flex flex-row w-[18rem] bg-[#ebecf1] rounded-2xl ">
        <img
          src={iconFind}
          alt="iconFind"
          className="h-6 w-6 absolute top-6 mx-2"
        />
        <input
          className="placeholder:text-slate-400 block bg-transparent w-full border text-black font-poppins rounded-2xl border-slate-300 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-gray-400 focus:ring-gray-300 focus:ring-1 sm:text-sm"
          placeholder="Buscar..."
          type="text"
          name="search"
        />
      </div> */}
      <div className="flex flex-row ">
        {/*Selector de idioma */}
        <div className="lg:flex hidden flex-row items-end relative right-0 menu mt-2 gap-2 cursor-pointer">
          <LanguageSelector/>
          {/* <img
            onClick={() => i18n.changeLanguage("en")}
            src={colombianFlag}
            alt="colombianFlag"
            style={{ width: "28px", height: "20px" }}
          />
          <p className="text-[#323A73] font-poppins">Español</p>
          <img
            src={expand}
            alt="expand"
            style={{ width: "24px", height: "24px" }}
          /> */}
        </div>
        {/*Lista de navegación para pantallas pequeñas */}
        <div className="md:hidden flex flex-row mt-2 min-w-[24.4rem] justify-between">
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <XIcon className="h-6 w-6" />
            ) : (
              <MenuIcon className="h-6 w-6" />
            )}
          </button>
          {isOpen && (
            <ul className="flex flex-col gap-8 text-[#323A73] font-poppins text-[1rem] ">
              {/* Your list items go here */}
              <li className="flex gap-2 cursor-pointer">
                <a className="flex" href="#home">
                  <img
                    src={iconHome}
                    alt="iconHome"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <p className="pl-2">{t("header.inicio")}</p>
                </a>
              </li>
              <li className="flex gap-2 cursor-pointer">
                <a className="flex" href="#aboutUs">
                  <img
                    src={iconAboutUs}
                    alt="IconAboutUs"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <p className="pl-2">{t("header.nosotros")}</p>
                </a>
              </li>
              <li className="flex gap-2 cursor-pointer">
                <a className="flex" href="#services">
                  <img
                    src={iconServices}
                    alt="iconServices"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <p className="pl-2">{t("header.servicios")}</p>
                </a>
              </li>
              <li className="flex gap-2 cursor-pointer">
                <a className="flex" href="#slider">
                  <img
                    src={iconAllies}
                    alt="IconAllies"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <p className="pl-2">{t("header.aliados")}</p>
                </a>
              </li>
              <li className="flex gap-2 cursor-pointer">
                <a className="flex" href="#contact">
                  <img
                    src={iconContact}
                    alt="iconContact"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <p className="pl-2">{t("header.contacto")}</p>
                </a>
              </li>
              <li className="flex gap-2 cursor-pointer">
                <a className="flex" href="#SocialMedia">
                  <img
                    src={iconHeart}
                    alt="iconHeart"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <p
                    onClick={() => scrollToSection("SocialMedia")}
                    className="pl-2"
                  >
                    {t("header.social")}
                  </p>
                </a>
              </li>
            </ul>
          )}
          {/*Selector de idioma */}
          <div className="flex flex-row md:items-end md:relative md:right-0 lg:right-auto menu mt-2 gap-2 pr-4 cursor-pointer">
            <LanguageSelector/>
            {/* <img
              onClick={() => i18n.changeLanguage("en")}
              src={colombianFlag}
              alt="colombianFlag"
              style={{ width: "28px", height: "20px" }}
            />
            <p className="text-[#323A73] font-poppins">Español</p>
            <img
              src={expand}
              alt="expand"
              style={{ width: "24px", height: "24px" }}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavMenu;
