import React, { useEffect, useState } from "react";
import "../../styles/index.css";
import medulaOsea from "../../assets/images/medulaOsea.svg";
import eyeLine from "../../assets/images/eye-line.svg";
import cirugia from "../../assets/icons/Cirugia.svg";
import medulaOsea2 from "../../assets/icons/Medula.svg";
import doctor from "../../assets/icons/doctor.svg";
import tooth from "../../assets/icons/tooth.svg";
import otorrino from "../../assets/icons/Otorrino.svg";
import { useTranslation } from "react-i18next";

function MedicalServices() {
  const {t} = useTranslation("common");
  const [option, setOption] = useState(1);
  const [data, setData] = useState({ title: "", body: "", img: "" });
  const handleOption = (optionSelected) => {
    setOption(optionSelected);
  };
  useEffect(() => {
    let title, img, body;
    switch (option) {
      case 2:
        title = t("medical_services.card_2_title")
        body = t("medical_services.card_2_body")
        img = doctor;
        break;
      case 3:
        title =  t("medical_services.card_3_title")
        body = t("medical_services.card_3_body")
          
        img = cirugia;
        break;
      case 4:
        title = t("medical_services.card_4_title");
        body =  t("medical_services.card_4_body");
        img = medulaOsea;
        break;

      case 5:
        title = t("medical_services.card_5_title")
        body = t("medical_services.card_5_body")
        img = tooth;
        break;

      case 6:
        title = t("medical_services.card_6_title")
        body = t("medical_services.card_6_body")
        img = otorrino;
        break;
      default:
        title = t("medical_services.card_1_title")
        body = t("medical_services.card_1_body")
        img = eyeLine;
        break;
    }
    setData({ title: title, body: body, img: img });
  }, [option, t]);
  return (
    <div id="services" className="flex flex-row w-full mt-16">
      <div className=" hidden lg:block flex-col w-100 max-w-[1024px] m-auto gap-12 ">
        <div className="flex flex-col justify-center items-center lg:gap-[0.9rem]">
          <p className="flex font-poppins lg:text-5xl text-2xl font-bold text-[#323A73] gap-2 lg:gap-4">
             {t("medical_services.titulo_1")} <span className="text-[#81C23D]">{t("medical_services.titulo_2")}</span>{" "}
          </p>
          <p className="flex font-poppins lg:text-5xl text-2xl font-bold text-[#323A73]">
            {t("medical_services.titulo_3")}
          </p>
        </div>
        <div className="relative min-h-[4rem]">{/* space */}</div>
        <div className="flex flex-col lg:flex-row h-full justify-between max-w-[32rem]">
          <div className="flex lg:w-[80%] min-w-[37rem] lg:h-[100%] justify-center items-center gap-7 flex-col bg-transparent backdrop-blur-md borderColorServices">
            <div className="relative top-2 flex w-[5.9rem]">
              <img className="w-full" src={data.img} alt="medula osea" />
            </div>
            <div className="flex ">
              <p className="font-poppins lg:text-4xl text-2xl font-bold text-[#81C23D]">
                {data.title}
              </p>
            </div>
            <div className="flex flex-col px-4">
              <ol>
                <li>
                  <p
                    className="font-poppins lg:text-[1.3rem] lg:font-medium text-justify lg:tracking-[0.3px] lg:leading-normal text-[#8489AB]"
                    dangerouslySetInnerHTML={{ __html: data.body }}
                  />
                </li>
              </ol>
            </div>
          </div>
          <div className=" px-6"></div>
          <div className="flex flex-col items-start lg:gap-6 gap-3 self-stretch">
            <div className="flex lg:flex-col gap-6">
              {/* eyeLine */}
              <div className="hover:h-6% hover:min-h-[3%] hover:translate-y-3">
                <div className=" ">
                  <div
                    onClick={() => handleOption(1)}
                    className="flex cursor-pointer px-3 py-1 justify-center gap-2 rounded-3xl bg-white w-[8rem] boxShadowelementsServices"
                  >
                    <img
                      src={eyeLine}
                      alt="eyeline"
                      className="h-[4.4rem] w-20"
                    />
                  </div>
                </div>
              </div>
              {/* Cardiology */}
              <div className="max-h-6% hover:min-h-[3rem] hover:translate-y-2">
                <div className=" ">
                  <div
                    onClick={() => handleOption(2)}
                    className="flex cursor-pointer px-3 py-1 justify-center gap-2 rounded-3xl bg-white w-[8rem] boxShadowelementsServices"
                  >
                    {" "}
                    <img
                      src={doctor}
                      alt="cardiology"
                      className="h-[4.4rem] w-18"
                    />
                  </div>
                </div>
              </div>
              {/* medical surgery */}
              <div className="max-h-6% hover:min-h-[3rem] hover:translate-y-1">
                <div className="">
                  <div
                    onClick={() => handleOption(3)}
                    className="flex cursor-pointer px-3 py-1 justify-center gap-2 rounded-3xl bg-white w-[8rem] boxShadowelementsServices"
                  >
                    {" "}
                    <img
                      src={cirugia}
                      alt="surgery"
                      className="h-[4.4rem] w-18"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Transplante de médula osea */}
            <div className="flex lg:flex-col gap-3">
              <div className="max-h-6% hover:min-h-[3rem] hover:translate-y-1">
                <div className="">
                  <div
                    onClick={() => handleOption(4)}
                    className="flex cursor-pointer px-3 py-1 justify-center gap-2 rounded-3xl bg-white w-[8rem] boxShadowelementsServices"
                  >
                    {" "}
                    <img
                      src={medulaOsea2}
                      alt="medulaOsea"
                      className="h-[4.4rem] w-18"
                    />
                  </div>
                </div>
              </div>
              {/* quinto componente */}
              <div className="max-h-6% hover:min-h-[3rem] hover:translate-y-2">
                <div className="  ">
                  <div
                    onClick={() => handleOption(5)}
                    className="flex cursor-pointer px-3 py-1 justify-center gap-2 rounded-3xl bg-white w-[8rem] boxShadowelementsServices"
                  >
                    {" "}
                    <img src={tooth} alt="tooth" className="h-[4.4rem] w-18" />
                  </div>
                </div>
              </div>
              {/* sexto componente */}
              <div className="max-h-6% hover:min-h-[3rem] hover:translate-y-3">
                <div className="  ">
                  <div
                    onClick={() => handleOption(6)}
                    className="flex cursor-pointer px-3 py-1 justify-center gap-2 rounded-3xl bg-white w-[8rem] boxShadowelementsServices "
                  >
                    {" "}
                    <img
                      src={otorrino}
                      alt="otorrino"
                      className="h-[4.4rem] w-18"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* responsive medical services */}
      <div className="lg:hidden flex flex-col w-90 max-w-[1024px] m-auto gap-12 ">
        <div className="flex flex-col justify-center items-center lg:gap-[0.9rem]">
          <p className="flex font-poppins lg:text-5xl text-2xl font-bold text-[#323A73] gap-2 lg:gap-4">
             {t("medical_services.titulo_1")} <span className="text-[#81C23D]">{t("medical_services.titulo_2")}</span>{" "}
          </p>
          <p className="flex font-poppins lg:text-5xl text-2xl font-bold text-[#323A73]">
            {t("medical_services.titulo_3")}
          </p>
        </div>
        <div className="flex flex-col lg:flex-row h-full justify-between w-[24rem]">
          <div className="flex lg:w-[80%] justify-center items-center gap-5 flex-col bg-transparent backdrop-blur-md borderColorServices">
            <div className="flex w-[5rem]">
              <img className="w-full" src={data.img} alt="medula osea" />
            </div>
            <div className="flex ">
              <p className="font-poppins lg:text-5xl text-2xl font-bold text-[#81C23D]">
                {data.title}
              </p>
            </div>
            <div className="flex flex-col">
              <ol>
                <li>
                  <p
                    className="font-poppins px-4 lg:text-xl lg:font-medium text-justify lg:tracking-[0.03px] lg:leading-normal text-[#8489AB]"
                    dangerouslySetInnerHTML={{ __html: data.body }}
                  />
                </li>
              </ol>
            </div>
          </div>
          <div className="flex flex-col max-w-[18rem] items-start mt-5 lg:gap-6 gap-3 self-stretch">
            <div className="flex lg:flex-col gap-3">
              {/* eye line */}
              <div
                onClick={() => handleOption(1)}
                className="flex cursor-pointer px-3 py-1 justify-center gap-2 rounded-3xl bg-white w-[8rem] boxShadowelementsServices"
              >
                <img src={eyeLine} alt="eyeline" className="h-[4.4rem] w-20" />
              </div>
              {/* doctor */}
              <div
                onClick={() => handleOption(2)}
                className="flex cursor-pointer px-3 py-1 justify-center gap-2 rounded-3xl bg-white w-[8rem] boxShadowelementsServices"
              >
                {" "}
                <img src={doctor} alt="doctor" className="h-[4.4rem] w-18" />
              </div>
              {/* cirugia */}
              <div
                onClick={() => handleOption(3)}
                className="flex cursor-pointer justify-center gap-2 rounded-3xl bg-white w-[8rem] boxShadowelementsServices max-w-[6.5rem]"
              >
                {" "}
                <img src={cirugia} alt="cirugia" className="h-[4.4rem] w-18" />
              </div>
            </div>
            {/* medulaOsea2 */}
            <div className="flex lg:flex-col gap-3">
              <div
                onClick={() => handleOption(4)}
                className="flex cursor-pointer px-3 py-1 justify-center gap-2 rounded-3xl bg-white w-[8rem] boxShadowelementsServices"
              >
                {" "}
                <img
                  src={medulaOsea2}
                  alt="medulaOsea2"
                  className="h-[4.4rem] w-18"
                />
              </div>
              {/* tooth */}
              <div
                onClick={() => handleOption(5)}
                className="flex cursor-pointer px-3 py-1 justify-center gap-2 rounded-3xl bg-white w-[8rem] boxShadowelementsServices"
              >
                {" "}
                <img src={tooth} alt="eyeline" className="h-[4.4rem] w-18" />
              </div>
              {/* otorrino */}
              <div
                onClick={() => handleOption(6)}
                className="flex cursor-pointer justify-center gap-2 rounded-3xl bg-white w-[8rem] boxShadowelementsServices max-w-[6.5rem]"
              >
                {" "}
                <img
                  src={otorrino}
                  alt="otorrino"
                  className="h-[4.4rem] w-18"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MedicalServices;
