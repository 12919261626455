"use client";

import { Button } from "flowbite-react";
import { useTranslation } from "react-i18next";

export default function Button1({url}) {
  const {t} = useTranslation("common");
  const redirect = () => {
    window.open(url, "_blank");
  };
  return (
    <div onClick={redirect}>
      <Button
        /* gradientDuoTone="purpleToBlue" */ className="bg-[#323a73] font-poppins rounded-3xl px-5 py-2"
      >
        + {t("slider.read_more")}
      </Button>
    </div>
  );
}
