import React, { useEffect, useState } from "react";
import "../../styles/divider.css";
function SocialMedia() {
  const [instagramData, setInstagramData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let token = "IGQWROQWwteWVUN2xnaFpNX0Y0THFsZAGNaeFlLSndDTC14VDE1WkNmNnRYYUpIckV2VE0zSDAtQTdBSEtYUHI4Q1FzRGlKUWhFX1luNThITGd5U3ZAEcjVuaWRSOTBwY01vRzdIcW5qV0ZAtRWdmZADllUHdiUnJGQnMZD"
    let fields = "id,caption,media_type,media_url,permalink"
    fetch(
      `https://graph.instagram.com/me/media?fields=${fields}&access_token=${token}`
    )
      .then((response) => response.json())
      .then((data) => {
        const filteredImages = data.data.filter(
          (item) => item.media_type === "IMAGE"
        );
        setInstagramData(filteredImages);
        setLoading(false);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <div
      id="SocialMedia"
      className="flex w-100  m-auto items-center lg:mb-10 flex-col w-full bg-[#323A73] pb-5"
    >
      <div className="flex flex-col lg:min-w-[1024px]">
        <p className="flex font-poppins text-[#fff] lg:text-[3.6rem]  text-3xl  font-bold lg:my-10 py-4 ">
          Social Media
        </p>
      </div>
      {loading && <span className="loader"></span>}
      {instagramData && !loading && (
        <>
          <div className="hidden lg:flex flex-col min-w-[1024px] gap-12 ">
            <div className="grid grid-cols-3 gap-10">
              <a
                href={instagramData[0].permalink}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex justify-center items-center rounded-[1.0rem] border-[2px] border-solid border-[#fff] instagramCards">
                  <img
                    key={instagramData[0].id}
                    src={instagramData[0].media_url}
                    alt={"post first one"}
                    className=" my-4 px-6    w-[23rem] "
                  />
                </div>
              </a>
              <a
                href={instagramData[1].permalink}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex justify-center items-center rounded-[1.0rem] border-[2px] border-solid border-[#fff] instagramCards">
                  <img
                    key={instagramData[1].id}
                    src={instagramData[1].media_url}
                    alt={"second post"}
                    className="my-4 px-6 w-[23rem] "
                  />
                </div>
              </a>
              <a
                href={instagramData[2].permalink}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex justify-center items-center rounded-[1.0rem] border-[2px] border-solid border-[#fff] instagramCards">
                  <img
                    key={instagramData[2].id}
                    src={instagramData[2].media_url}
                    alt="third post "
                    className="my-4 px-6 w-[23rem] "
                  />
                </div>
              </a>
            </div>

            <div className="grid grid-cols-3 gap-10">
              <a
                href={instagramData[3].permalink}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex justify-center items-center rounded-[1.0rem] border-[2px] border-solid border-[#fff] instagramCards">
                  <img
                    key={instagramData[3].id}
                    src={instagramData[3].media_url}
                    alt="fourth post"
                    className="my-4 px-6 w-[23rem] "
                  />
                </div>
              </a>
              <a
                href={instagramData[4].permalink}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex justify-center items-center rounded-[1.0rem] border-[2px] border-solid border-[#fff] instagramCards">
                  <img
                    key={instagramData[4].id}
                    src={instagramData[4].media_url}
                    alt="fifth post"
                    className="my-4 px-6 w-[23rem] "
                  />
                </div>
              </a>
              <a
                href={instagramData[5].permalink}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex justify-center items-center rounded-[1.0rem] border-[2px] border-solid border-[#fff] instagramCards">
                  <img
                    key={instagramData[5].id}
                    src={instagramData[5].media_url}
                    alt="sixth post"
                    className="my-4 px-6 w-[23rem] "
                  />
                </div>
              </a>
            </div>
          </div>

          <div className="lg:hidden flex flex-col gap-3 w-[94%] ">
            <div className="grid grid-cols-2 gap-5">
              <a
                href={instagramData[0].permalink}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex justify-center items-center rounded-[1.0rem] border-[2px] border-solid border-[#fff] instagramCards">
                  <img
                    key={instagramData[0].id}
                    src={instagramData[0].media_url}
                    alt={"post first one"}
                    className="lg:my-4 my-3 px-3 lg:px-6 rounded-[1.0rem]"
                  />
                </div>
              </a>
              <a
                href={instagramData[1].permalink}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex justify-center items-center rounded-[1.0rem] border-[2px] border-solid border-[#fff] instagramCards">
                  <img
                    key={instagramData[1].id}
                    src={instagramData[1].media_url}
                    alt={"second post"}
                    className="lg:my-4 my-3 px-3 lg:px-6 rounded-[1.0rem]"
                  />
                </div>
              </a>
            </div>
            <div className="grid grid-cols-2 gap-5">
              <a
                href={instagramData[2].permalink}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex justify-center items-center rounded-[1.0rem] border-[2px] border-solid border-[#fff] instagramCards">
                  <img
                    key={instagramData[2].id}
                    src={instagramData[2].media_url}
                    alt={"post first one"}
                    className="lg:my-4 my-3 px-3 lg:px-6 rounded-[1.0rem]"
                  />
                </div>
              </a>
              <a
                href={instagramData[3].permalink}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex justify-center items-center rounded-[1.0rem] border-[2px] border-solid border-[#fff] instagramCards">
                  <img
                    key={instagramData[3].id}
                    src={instagramData[3].media_url}
                    alt={"second post"}
                    className="lg:my-4 my-3 px-3 lg:px-6 rounded-[1.0rem]"
                  />
                </div>
              </a>
            </div>
            <div className="grid grid-cols-2 gap-5">
              <a
                href={instagramData[4].permalink}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex justify-center items-center rounded-[1.0rem] border-[2px] border-solid border-[#fff] instagramCards">
                  <img
                    key={instagramData[4].id}
                    src={instagramData[4].media_url}
                    alt={"post first one"}
                    className="lg:my-4 my-3 px-3 lg:px-6 rounded-[1.0rem]"
                  />
                </div>
              </a>
              <a
                href={instagramData[5].permalink}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex justify-center items-center rounded-[1.0rem] border-[2px] border-solid border-[#fff] instagramCards">
                  <img
                    key={instagramData[5].id}
                    src={instagramData[5].media_url}
                    alt={"second post"}
                    className="lg:my-4 my-3 px-3 lg:px-6 rounded-[1.0rem]"
                  />
                </div>
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default SocialMedia;
