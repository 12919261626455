import React from "react";
import footerLogo from "../../assets/images/LOGOblue.svg";
import call from "../../assets/icons/call.svg";
import point from "../../assets/icons/Point.svg";
import emailGreen from "../../assets/icons/emailgreen.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import instagram from "../../assets/icons/instagram.svg";
import facebook from "../../assets/icons/facebook.svg";

function footer() {
  return (
    <div>
      <div className="flex flex-col w-100 h-full lg:max-w-[1024px] m-auto w-full bg-footer">
        <div className="flex w-full lg:h-[13rem] content-center  justify-center lg:justify-between flex-wrap lg:relative lg:top-[27%]">
          <div className="flex flex-row py-3 lg:top-[10%] lg:relative ">
            <img
              className="lg:w-[335px] lg:h-[81px]"
              src={footerLogo}
              alt="Footerlogo"
            // style={{ width: "353px", height: "81px" }}
            />
          </div>
          <div className="flex-col lg:inline-flex items-start ">
            <ul className="flex flex-col lg:relative lg:bottom-10 gap-8">
              <li className="flex gap-2 menu text-[#323A73]">
                <img
                  src={call}
                  alt="logo"
                  style={{ width: "24px", height: "24px" }}
                />
                <p>605-3226161 ext 323</p>
              </li>
              <li className="flex gap-2 menu text-[#323A73] ">
                <img
                  src={emailGreen}
                  alt="logo"
                  style={{ width: "24px", height: "24px" }}
                />
                <a href="mailto:info@caribbeanhealthgroup.com">
                  info@caribbeanhealthgroup.com
                </a>
              </li>
              <li className="flex gap-2 menu text-[#323A73] ">
                <img
                  src={point}
                  alt="logo"
                  style={{ width: "24px", height: "24px" }}
                />
                <div className="flex flex-col">
                  <p className="text-[#323A73]">
                    Cra 51B N 87 – 50 piso 3 C.C Viva
                  </p>
                  <p className="text-[#323A73]">Barranquilla, Atlantico</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="flex flex-col py-3 z-10">
            <div className="flex flex-col text-[#323A73]  ">
              <a href="https://docs.google.com/uc?export=download&id=1UpxOPrYXIPsC4OS5xJDQbsn1XrAIn5Hh" className="py-1">Política de protección de datos personales</a>
              <a href="https://docs.google.com/uc?export=download&id=1RWS4VBH28um9yHQiX516VRswl3JW2IpR" className="py-1">Política de cookies</a>
              <a href="https://docs.google.com/uc?export=download&id=1pVjzvZFaSTRFbuDAAnSMMDQ0jobuPIy0" className="py-1">Aviso de privacidad</a>
              <a href="https://docs.google.com/uc?export=download&id=1KCGinV6AXjdZi6nA0POh_Q27Z7bCNda3" className="py-1">Política de propiedad intelectual e industrial</a>
              <a href="https://docs.google.com/uc?export=download&id=17x-1mgw6I15e_0SR5gPRMJNz7PFUCvvX" className="py-1">Estados financieros 2023</a>
            </div>
            <ul className="inline-flex py-3 gap-8 lg:pr-28 items-center relative content-end flex-wrap">
              <a
                href="https://www.linkedin.com/company/caribbean-health-group/"
                target="_blank"
                rel="noreferrer"
              >
                <li>
                  <img
                    src={linkedin}
                    alt="linkedin"
                    style={{ width: "35px", height: "35px" }}
                  />
                </li>
              </a>
              <a
                href="https://www.instagram.com/caribbeanhealthgroup/"
                target="_blank"
                rel="noreferrer"
              >
                <li>
                  <img
                    src={instagram}
                    alt="instagram"
                    style={{ width: "35px", height: "35px" }}
                  />
                </li>
              </a>
              <a
                href="https://www.facebook.com/caribbeanhealthgroup/"
                target="_blank"
                rel="noreferrer"
              >
                <li>
                  <img
                    src={facebook}
                    alt="facebook"
                    style={{ width: "35px", height: "35px" }}
                  />
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex relative bottom-[3.6rem] min-w-[100%]">
        <div className="custom-shape-divider-bottomInstagram-1695183898 ">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default footer;
